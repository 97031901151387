import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function NavBar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '0', borderRadius: 0, marginTop: '10px' }}>
            <AppBar 
                position="static" 
                sx={{ 
                    borderRadius: '0', 
                    width: '100%', 
                    boxShadow: '0px 0px 0px #fff',
                    backgroundColor: 'white',
                    padding: '0 0rem',
                }}
            >
                <Toolbar sx={{ justifyContent: 'space-between', padding: '0' }} style={{ paddingLeft: '0px', paddingRight: '0px'}}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <a href="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                            <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', color: '#333', paddingBottom: '3px' }}>
                                tenderead
                            </Typography>
                        </a>
                    </Box>
                    {isMobile ? (
                        <>
                            <IconButton edge="start" aria-label="menu" onClick={handleMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                sx={{ marginTop: '0px', marginLeft: '-25px', border: '0.5px solid #000' }}
                            >
                                {/* <MenuItem onClick={handleMenuClose} component="a" href="/signin">Login</MenuItem> */}
                                {/* <MenuItem onClick={handleMenuClose} component="a" href="/signup">Create Account</MenuItem> */}
                                <MenuItem onClick={handleMenuClose} component="a" href="/dashboard">Start Using</MenuItem>
                            </Menu>
                        </>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* <Button
                            variant="outlined"
                            color="primary"
                            borderRadius={0}
                            size='large'
                            href='/signin'
                            >
                            Login
                            </Button> */}
                            {/* <Button
                            variant="contained"
                            color="primary"
                            borderRadius={0}
                            size='large'
                            style={{ marginLeft: '1rem' }}
                            href='/signup'
                            >
                            Create Account
                            </Button> */}
                            <Button
                            variant="contained"
                            color="primary"
                            borderRadius={0}
                            size='large'
                            style={{ marginLeft: '1rem' }}
                            href='/dashboard'
                            >
                            <span
                                style={{
                                    fontWeight: 'bold'
                                }}
                            >
                                Start
                            </span> 
                            <ArrowForwardIcon
                                style={{ marginLeft: '5px' }}
                            />
                            </Button>
                            {/* <Button color="inherit" href="#about" sx={{ marginRight: '10px', textTransform: 'none' }}>About</Button>
                            <Button color="inherit" href="#features" sx={{ marginRight: '10px', textTransform: 'none' }}>Features</Button>
                            <Button color="inherit" href="#how-it-works" sx={{ marginRight: '10px', textTransform: 'none' }}>How it works</Button>
                            <Button variant="outlined" color="primary" href="/signin" sx={{ marginRight: '10px', textTransform: 'none', borderRadius: 0 }}>Login</Button>
                            <Button variant="contained" color="primary" href="/signup" sx={{ marginRight: '10px', textTransform: 'none', borderRadius: 0 }}>Create Account</Button> */}
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default NavBar;