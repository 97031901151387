import React, { useState, useRef, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Container from '@mui/material/Container';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CircleIcon from '@mui/icons-material/Circle';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Alert } from "@mui/material";


const JSONFileHandler = () => {
    const [fileHandle, setFileHandle] = useState(null);
    const [jsonData, setJsonData] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const textAreaRefs = useRef([]);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [drawerOpen, setOpen] = React.useState(false);
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    useEffect(() => {
        textAreaRefs.current = textAreaRefs.current.slice(0, blocks.length);
    }, [blocks]);

    // Function to handle file selection and read its content
    const handleFileSelect = async () => {
        try {
            const [handle] = await window.showOpenFilePicker({
                types: [{
                    description: 'JSON Files',
                    accept: {'application/json': ['.json']},
                }],
            });
            setFileHandle(handle);
            const file = await handle.getFile();
            const fullJSON = await file.text();
            if (fullJSON.trim() === "" || fullJSON.trim() === "[]") {
                const newContent = [{ id: `${Date.now()}`, title: 'New Note', content: [] }];
                setJsonData(newContent);
                localStorage.setItem('noteID', newContent[0].id);
                const writable = await handle.createWritable();
                await writable.write(JSON.stringify(newContent, null, 2));
                await writable.close();
            } else {
                const jsonContent = JSON.parse(fullJSON);
                setJsonData(jsonContent);
            }
            setFileUploaded(true);
        } catch (error) {
            alert("Please select a valid JSON file.");
        }
    };

    useEffect(() => {
        const noteID = localStorage.getItem('noteID');
        if (noteID === null && fileUploaded) {
            setOpen(true);
        }
    }, [fileUploaded]);



    useEffect(() => {
        const noteID = localStorage.getItem('noteID');
        if (jsonData && fileUploaded && noteID) {
            const note = jsonData.find(note => note.id === noteID);
            // console.log(note.content.length === 0);
            if (note.content.length === 0) {
                const newContent = [];
                newContent.push({ id: `${Date.now()}`, text: '', position: 0, depth: 0 });
                setBlocks(newContent);
            } else {
                setBlocks(note.content);
            }
        }
        
    }, [jsonData, fileUploaded]);

    const handleKeyDown = (e, id) => {
        const index = blocks.findIndex(block => block.id === id);
        if (e.key === 'Enter') {
            e.preventDefault();
            const newBlocks = [...blocks];
            const newBlock = { id: `${Date.now()}`, text: '', position: index + 1, depth: blocks[index].depth };
            newBlocks.splice(index + 1, 0, newBlock);
            // Update positions of subsequent blocks
            for (let i = index + 2; i < newBlocks.length; i++) {
                newBlocks[i].position += 1;
            }
            setBlocks(newBlocks);
            setTimeout(() => {
                textAreaRefs.current[index + 1].focus();
            }, 0);
        } else if (e.key === 'Tab') {
            e.preventDefault();
            const newBlocks = blocks.map(block => {
                if (block.id === id) {
                    if (e.shiftKey) {
                        return { ...block, depth: Math.max(block.depth - 1, 0) }; // Min depth of 0
                    } else {
                        return { ...block, depth: Math.min(block.depth + 1, 2) }; // Max depth of 2
                    }
                }
                return block;
            });
            setBlocks(newBlocks);
        } else if (e.key === 'Backspace') {
            if (blocks[index].text === '' || (e.target.selectionStart === 0 && e.target.selectionEnd === 0)) {
                e.preventDefault();
                if (index > 0) {
                    const newBlocks = [...blocks];
                    const previousBlock = newBlocks[index - 1];
                    const currentBlock = newBlocks[index];
                    previousBlock.text += currentBlock.text;
                    newBlocks.splice(index, 1);
                    for (let i = index; i < newBlocks.length; i++) {
                        newBlocks[i].position -= 1;
                    }
                    setBlocks(newBlocks);
                    setTimeout(() => {
                        textAreaRefs.current[index - 1].focus();
                        textAreaRefs.current[index - 1].selectionStart = previousBlock.text.length;
                        textAreaRefs.current[index - 1].selectionEnd = previousBlock.text.length;
                    }, 0);
                }
            }
        } else if (e.key === 'ArrowUp') {
            if (index > 0 && e.target.selectionStart === 0 && e.target.selectionEnd === 0) {
                e.preventDefault();
                setTimeout(() => {
                    textAreaRefs.current[index - 1].focus();
                }, 0);
            }
        } else if (e.key === 'ArrowDown') {
            if (index < blocks.length - 1 && e.target.selectionStart === e.target.value.length && e.target.selectionEnd === e.target.value.length) {
                e.preventDefault();
                setTimeout(() => {
                    textAreaRefs.current[index + 1].focus();
                }, 0);
            }
        }
    };

    const handleChange = (e, id) => {
        const newBlocks = blocks.map(block => {
            if (block.id === id) {
                return { ...block, text: e.target.value };
            }
            return block;
        });
        setBlocks(newBlocks);
    };

    useEffect(() => {
        if (blocks.length > 0) {
            const noteID = localStorage.getItem('noteID');
            if (fileUploaded && noteID) {
                const updatedJsonData = jsonData.map(note => {
                    if (note.id === noteID) {
                        return { ...note, content: blocks };
                    }
                    return note;
                });
                setJsonData(updatedJsonData);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blocks, fileUploaded]);


    useEffect(() => {
        const updateJsonFile = async () => {
            if (fileHandle) {
                const writable = await fileHandle.createWritable();
                await writable.write(JSON.stringify(jsonData, null, 2));
                await writable.close();
            }
        };
        if (fileUploaded) {
            updateJsonFile();
        }
    }, [jsonData, fileHandle, fileUploaded]);



    const onDragEnd = (result) => {
        if (!result.destination) return;
    
        const newBlocks = Array.from(blocks);
        const [movedBlock] = newBlocks.splice(result.source.index, 1);
        newBlocks.splice(result.destination.index, 0, movedBlock);
    
        // Update positions
        newBlocks.forEach((block, index) => {
            block.position = index;
        });

        setBlocks(newBlocks);
    };

    return (
        <div style={{ padding: "10px 20px", fontFamily: "Figtree" }}>

            {jsonData.length === 0 && (
                <Dialog open={true}
                    sx={{
                        "& .MuiDialog-container": {
                            alignItems: "flex-start"
                        }
                    }}   
                >
                    <DialogTitle style={{
                        backgroundColor: 'black',
                        color: 'white',
                        textAlign: 'center',
                        marginBottom: '20px',
                    }}>
                        Welcome to <span style={{ fontWeight: 'bolder' }}>tenderead!</span>
                    </DialogTitle>
                    {!window.showOpenFilePicker && (
                        <Alert variant="outlined" severity="error" style={{margin: '10px'}} >
                            Please use a modern browser like Chrome or Edge.
                        </Alert>
                    )}
                    <DialogContent>
                    <Button
                    variant="contained"
                    component="label"
                    style={{
                        backgroundColor: 'white',
                        height: '180px',
                        textAlign: 'center',
                        border: '1px solid black',
                        boxShadow: 'none',
                    }}
                    >   
                        <div>
                        <NoteAddIcon style={{
                            color: 'black',
                            fontSize: '100px',
                        }}/>
                        <br />
                        <div style={{
                            fontSize: '18px',
                            fontWeight: 'normal',
                            color: 'black',
                            textTransform: 'none',
                            minWidth: '150px',
                        }}>
                            Upload a workspace
                        </div>
                        </div>
                        <input
                            type="file"
                            accept=".json"
                            onClick={(e) => {
                                e.preventDefault();
                                handleFileSelect();
                            }}
                            hidden
                        />
                    </Button>

                    <Button
                        variant="contained"
                        onClick={async () => {
                            const newFileHandle = await window.showSaveFilePicker({
                                suggestedName: 'workspace.json',
                                types: [{
                                    description: 'JSON Files',
                                    accept: {'application/json': ['.json']},
                                }],
                            });
                            setFileHandle(newFileHandle);
                            const newContent = [{ id: `${Date.now()}`, title: 'New Note', content: [] }];
                            setJsonData(newContent);
                            localStorage.setItem('noteID', newContent[0].id);
                            const writable = await newFileHandle.createWritable();
                            await writable.write(JSON.stringify(newContent, null, 2));
                            await writable.close();
                            setFileUploaded(true);
                        }}
                        style={{ 
                            marginLeft: '10px',
                            backgroundColor: 'white',
                            height: '180px',
                            border: '1px solid black',
                            boxShadow: 'none',
                        }}
                    >
                        <div>
                        <UploadFileIcon style={{
                            color: 'black',
                            fontSize: '100px',
                        }}/>
                        {/* <br /> */}
                        <div style={{
                            fontSize: '18px',
                            fontWeight: 'normal',
                            color: 'black',
                            textTransform: 'none',
                            minWidth: '150px',
                        }}>
                            Create a workspace
                        </div>
                        </div>
                    </Button>
                    </DialogContent>
                </Dialog>
            )}

            {jsonData && (
                <div>
                    <div
                        style={{
                            fontSize: '24px',
                            fontWeight: 'bolder',
                            cursor: 'pointer',
                            padding: '10px',
                            borderRadius: '4px',
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}
                    >
                        <MenuIcon onClick={toggleDrawer(true)} />
                        <span style={{ marginLeft: '16px' }}>
                            tenderead
                        </span>
                    </div>
                    <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
                        <Box sx={{ width: 280 }} role="presentation" onClick={toggleDrawer(false)}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                // padding: '36px',
                                // paddingTop: '20px',
                            }}>
                                <h2
                                    style={{
                                        fontSize: '24px',
                                        fontWeight: 'bolder',
                                        padding: '20px',
                                        borderBottom: '1px solid #f0f0f0',
                                        marginBottom: '0px',
                                        paddingTop: '10px',
                                    }}
                                >
                                    <span>Workspace</span>
                                </h2>
                                <List
                                    style={{
                                        padding: '0px',
                                    }}
                                >
                                {jsonData.map(note => (
                                    <div key={note.id}
                                        style={{
                                            padding: '5px 20px',
                                            borderBottom: '1px solid #f0f0f0',
                                            cursor: 'pointer',
                                            margin: '0px',
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                                    >
                                        <button 
                                            onClick={() => {
                                                localStorage.setItem('noteID', note.id)
                                                setBlocks(note.content);}}
                                            style={{
                                                color: 'inherit',
                                                border: 'none',
                                                font: 'inherit',
                                                cursor: 'pointer',
                                                backgroundColor: 'transparent',
                                            }}
                                            
                                        >
                                            {note.title}
                                        </button>
                                        
                                    </div>
                                ))}
                                </List>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '20px',
                                    }}
                                >
                                    <Button 
                                    fullWidth
                                        variant="contained"
                                        size="small"
                                        style={{
                                            marginTop: '10px',
                                            backgroundColor: 'black',
                                            color: 'white',
                                            textTransform: 'none',
                                        }}
                                        onClick={() => {
                                            const newNote = { id: `${Date.now()}`, title: 'New Note', content: [] };
                                            setJsonData([...jsonData, newNote]);
                                            localStorage.setItem('noteID', newNote.id);
                                            setBlocks(newNote.content);
                                        }}
                                    >
                                        Add New Note
                                    </Button>
                                </div>
                            </div>
                        </Box>
                    </Drawer>
                </div>
            )}

            {fileUploaded && localStorage.getItem('noteID') === null && (
                <Container>
                    <Box>
                        <h1 style={{ textAlign: 'center' }}>Welcome to tenderead!</h1>
                        <p style={{ textAlign: 'center' }}>Please select a note from the sidebar to get started.</p>
                    </Box>
                </Container>
            )}

            {blocks && fileUploaded && jsonData.length > 0 && (
                <>
                    <div>
                    <Container
                    style={{
                        marginTop: '42px',
                        maxWidth: '800px',
                    }}>
                    {jsonData.find(note => note.id === localStorage.getItem('noteID')) && (
                        <textarea
                            spellCheck="false"
                            type="text"
                            value={jsonData.find(note => note.id === localStorage.getItem('noteID')).title}
                            onChange={(e) => {
                                const updatedJsonData = jsonData.map(note => {
                                    if (note.id === localStorage.getItem('noteID')) {
                                        return { ...note, title: e.target.value };
                                    }
                                    return note;
                                });
                                setJsonData(updatedJsonData);
                            }}
                            style={{
                                fontSize: '38px',
                                paddingLeft: '32px',
                                border: 'none',
                                outline: 'none',
                                width: '90%',
                                fontWeight: 'bolder',
                                backgroundColor: 'transparent',
                                fontFamily: 'inherit',
                                marginBottom: '20px',
                                overflow: 'hidden',
                                resize: 'none',
                            }}
                            rows={Math.min(3, Math.max(1, Math.ceil((jsonData.find(note => note.id === localStorage.getItem('noteID')).title.length) / (window.innerWidth / 20))))}
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                        />
                    )}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="blocks">
                            {(provided) => (
                                <div 
                                    {...provided.droppableProps} 
                                    ref={provided.innerRef}
                                    style={{
                                        maxWidth: '800px',
                                    }}>
                                    <div className="block-editor">
                                        {Array.isArray(blocks) && blocks.map((block, index) => (
                                            block.id && <Draggable key={block.id} draggableId={block.id.toString()} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="block"
                                                    >
                                                        <div style={{ marginLeft: `${block.depth * 40}px` }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div
                                                                style={{
                                                                    opacity: 0,
                                                                    transition: 'opacity 0.3s',
                                                                    alignItems: 'center',
                                                                }}
                                                                onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
                                                                onMouseLeave={(e) => e.currentTarget.style.opacity = 0}
                                                            >
                                                            <DragIndicatorIcon 
                                                                className='drag-icon' 
                                                                style={{ 
                                                                    cursor: 'grab', 
                                                                    fontSize: 'medium', 
                                                                    backgroundColor: 'f0f0f0',
                                                                    color: 'grey', 
                                                                    borderRadius: '20%',
                                                                    padding: '5px',
                                                                    margin: '0px',
                                                                    paddingTop: '5px',
                                                                    verticalAlign: 'middle',
                                                                    marginRight: '5px',
                                                                }} 
                                                            />
                                                            </div>
                                                            {block.depth !== 0 ? (
                                                                <CircleIcon 
                                                                    style={{ 
                                                                        color: '#000', 
                                                                        fontSize: '8px', 
                                                                        verticalAlign: 'top',
                                                                        marginRight: '5px',
                                                                    }}/>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <textarea
                                                                spellCheck="false"
                                                                style={{ 
                                                                    width: '100%', 
                                                                    padding: '5px', 
                                                                    fontSize: '16px', 
                                                                    overflow: 'hidden', 
                                                                    resize: 'none', 
                                                                    height: 'auto', 
                                                                    border: 'none',
                                                                    outline: 'none',
                                                                    transition: 'background-color 0.5s',
                                                                    verticalAlign: 'bottom',
                                                                    borderRadius: '2px',
                                                                    fontFamily: 'inherit',
                                                                    lineHeight: 'inherit',
                                                                }}
                                                                // onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                                                                // onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'white'}
                                                                ref={el => {
                                                                    textAreaRefs.current[index] = el;
                                                                    if (el) {
                                                                        el.style.height = 'auto';
                                                                        el.style.height = `${el.scrollHeight - 8}px`;
                                                                    }
                                                                }}
                                                                value={block.text}
                                                                onChange={(e) => handleChange(e, block.id)}
                                                                onKeyDown={(e) => handleKeyDown(e, block.id)}
                                                                placeholder={blocks.length === 1 ? "Type here..." : ""}
                                                                onInput={(e) => {
                                                                    e.target.style.height = 'auto';
                                                                    e.target.style.height = `${e.target.scrollHeight}px`;
                                                                }}
                                                                rows={1}
                                                            />
                                                        </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    </Container>
                    </div>
                </>
            )}
        </div>
    );
};

export default JSONFileHandler;
