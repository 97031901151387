import * as React from 'react';
import Hero from './components/Landing/Hero';
import LogoCollection from './components/Landing/LogoCollection';
import Testimonials from './components/Landing/Testimonials';
import Footer from './components/Landing/Footer';
import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import theme from './theme/theme';
import NavBar from './components/Landing/NavBar';

export default function MarketingPage() {
  return (
    <ThemeProvider theme={theme}>
    <div>
      <Container>
        <NavBar />
      </Container>
      <Hero />
      <div>
        <LogoCollection />
        <Testimonials />
        <Footer />
      </div>
    </div>
    </ThemeProvider>
  );
}