import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function Hero() {
  return (
    <Box id="hero">
      <Container
        style={{
          marginTop: '30px',
          marginBottom: '0px',
        }}
      >
        <div
          spacing={2}
          useFlexGap
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div id='left-side' style={{ flex: 1 }}>
            <div
              style={{
                fontSize: '72px',
                fontWeight: 'bolder',
                lineHeight: '5rem',
                marginBottom: '1rem',
                // textDecoration: 'underline',
              }}
            >
              <div>
                Simplicity
              </div>
              <div>
                Privacy
              </div>
              <div>
                User-Friendly
              </div>
              <div>
                No Login
              </div>
            </div>
            <Typography
              sx={{
                color: 'text.secondary',
                fontSize: '1rem',
                lineHeight: '1.75rem',
                marginBottom: '2rem',
                width: '80%',
              }}
            >
            A minimalist, no data collected, completely free, user-friendly locally stored note-taking platform designed with privacy at its core. Effortlessly create, edit, and organize notes in real time, with data encrypted and securely stored to ensure your thoughts remain yours. 
            </Typography> 
            <div>
              <Button
                variant="contained"
                color="primary"
                borderRadius={10}
                size='large'
                style={{ marginRight: '1rem' }}
                href='/dashboard'
              >
                <span style={{
                  fontWeight: 'bolder',
                }}>
                  Start
                </span>
                <ArrowForwardIcon 
                style={{
                  marginLeft: '5px',
                }}/>
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                borderRadius={10}
                size='large'
                style={{ marginRight: '1rem' }}
                href='/signup'
              >
                Create Account
              </Button> */}
              {/* <Button
                variant="outlined"
                color="primary"
                borderRadius={10}
                size='large'
                href='/signin'
              >
                Login
              </Button> */}
            </div>
          </div>
          <div id='right-side' style={{ 
            flex: 1,
            padding: '5rem',
            paddingRight: '0',
          }}>
            <img
              src="/static/hero.png"
              alt="hero"
              style={{
                width: '100%',
                height: 'auto',
                outline: '6px solid',
                outlineColor: 'hsla(220, 25%, 80%, 0.2)',
                boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
                borderRadius: '12px',
                backgroundSize: 'cover',
                display: 'flex',
              }}
            />
          </div>
        </div>
      </Container>
    </Box>
  );
}
