import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import NotePage from './Note';
// import Signup from './Signup';
// import Login from './Signin';
// import Dashboard from './Dashboard';
import Landing from './Landing';
import JSONFileHandler from './Home';
import AppDashboard from './testHome';

function App() {
  React.useEffect(() => {
    if (window.location.pathname === '/dashboard') {
      localStorage.clear();
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="" element={<Landing />} />
        {/* <Route path="signup" element={<Signup />} /> */}
        {/* <Route path="signin" element={<Login />} /> */}
        {/* <Route path="dashboard" element={<Dashboard />} /> */}
        {/* <Route path="/note" element={<NotePage />} /> */}
        <Route path="dashboard" element={<JSONFileHandler />} />
        <Route path="home" element={<AppDashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
// No additional code needed at $PLACEHOLDER$